import React from 'react';
import { connect } from "react-redux";
import { compose } from "recompose";
import { withRouter } from 'react-router-dom';
import { getNewPlatform } from 'util/Environment';

const connectedProps = (state) => ({
});

const connectionActions = {
}

var connector = connect(connectedProps, connectionActions);

class PublicForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    }

  }

  componentDidMount() {
    window.open(`${getNewPlatform()}/${window.location.pathname}`, '_self')
  }

  render() {
    return <div></div>
  }
}

export default connector(compose(
  withRouter
)(PublicForm));
