import React from 'react';
import { connect } from "react-redux";
import { createStyles } from '@material-ui/core/styles';
import { compose } from "recompose";
import { withStyles } from '@material-ui/core/styles';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import { Switch, Route } from "react-router-dom";

// style
import ExpentTheme, { ThemeType } from './../theme';

// views
import NotFound from "./NotFound";
import Login from "./Login";
import Register from "./Register";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import CreatePassword from "./CreatePassword";
import ResetPasswordConfirm from "./ResetPasswordConfirm";
import Application from "./Application/index";
import SellerApplication from "./SellerApplication/index";
// import Seller from "./Seller/index";
import SellerV2 from "./Seller";
import OnBoarding from "./OnBoarding/index";
import Splash from "./Splash";
import CognitoError from "./CognitoError";
import SSORegister from "./SSORegister";
import EvaluationSummaryReport from "./Reports/EvaluationSummaryReport"
import CacheBuster from './CacheBuster';
import InstructionDownload from './Reports/InstructionDownload';
import CodeMirrorExample from './Application/Components/Settings/Automations/Common/codeMirror';
import PublicForm from './Application/Components/PublicForm';

const connectedProps = (state) => ({

});

const connectionActions = {

}


var connector = connect(connectedProps, connectionActions);

const styles = (theme) => createStyles({
  "@global": {
    "html,body,#root": {
      margin: 0,
      height: '100%',
      background: "#fff",
      scrollBehavior: 'smooth'
    },
    "*": {
      boxSizing: "border-box",
      'scrollbar-width': 'thin',
    },
    "a": {
      textDecoration: "none",
      "&:any-link": {
        color: theme.palette.secondary.main,
      },
      "&:any-link:active": {
        color: theme.palette.secondary.main,
      }
    },

    "*::-webkit-scrollbar": {
      width: '5px',
      height: '5px'
    },

    '*::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },

    '*::-webkit-scrollbar-thumb': {
      background: '#888'
    },

    '*::-webkit-scrollbar-thumb:hover': {
      background: '#555'
    },
    "#evMetaDialogApprovalCondition": {
      "& .react-datepicker-popper": {
        zIndex: 2200,
      }
    },
    'body.evaluate-view': {
      '& .evaluate-save-template, .evaluate-add-approver, .evaluate-edit-icon, .evaluate-delete-icon, .evaluate-add-schedule, .evaluate-product-remove, .evaluate-seller-email,  .evaluate-requirement-action, .evaluate-add-requirement, .evaluate-template, .evaluate-response-edit, .evaluate-add-user, .evaluate-ask-seller-btn, .evaluate-add-text, .evaluate-add-members, .evaluate-collaboration, .evaluate-product-slider, .evaluate-pagination': {
        visibility: 'hidden',
        pointerEvents: 'none',
      },
      '& .evaluate-add-new-criteria, .evaluate-save-continue, .evaluate-proceed-btn, .evaluate-get-approval, .evaluate-add-schedule, .evaluate-flag-section, .evaluate-reply, .evaluate-seller-edit, .evaluate-procure, .evaluate-approval-action': {
        display: 'none'
      },
      "& .evaluate-schedule, .evaluate-approval-body": {
        pointerEvents: 'none',
      },
      "& .evaluate-description-container": {
        marginTop: 0,
      },
      '& .evaluate-priority, .evaluate-scoring-type, .evaluate-select-result': {
        '& [class*=MuiSelect-iconOutlined]': {
          display: 'none',
        },
        "& [class*=MuiFormControl-root], [class*=MuiButtonBase-root]": {
          pointerEvents: 'none',
        }
      },
      '& .evaluate-next, .evaluate-view-approval, .evaluate-finish': {
        display: 'initial',
      }
    },
    '.evaluate-next, .evaluate-view-approval, .evaluate-finish': {
      display: 'none',
    },
  },

});

class Expent extends React.Component {

  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }

          return (<ThemeProvider theme={ExpentTheme(ThemeType.LIGHT)}>
            <CssBaseline />
            <Switch>
              <Route exact path={"/"} component={Login} />
              <Route exact path={"/register"} component={Register} />
              <Route exact path={"/sso-register"} component={SSORegister} />
              <Route exact path={"/splash"} component={Splash} />
              <Route exact path={"/forgot-password"} component={ForgotPassword} />
              <Route exact path={"/reset-password/:verifyToken"} component={ResetPassword} />
              <Route exact path={"/password-update/confirm"} component={ResetPasswordConfirm} />
              <Route exact path={"/create-password/:verifyToken"} component={CreatePassword} />
              <Route exact path={"/cognito-login/error"} component={CognitoError} />

              <Route path={"/onboard"} component={OnBoarding} />
              <Route path={"/app"} component={Application} />
              <Route path={"/sellerapp"} component={SellerApplication} />
              <Route path={"/seller"} component={SellerV2} />
              <Route path={"/evaluation/:evaluation_id/report"} component={EvaluationSummaryReport} />
              <Route path={"/evaluation/:evaluation_id/component/:component_id/:form_type/:form_id/o_id/:org_id"} component={PublicForm} />
              <Route path={"/evaluation/:evaluation_id/component/:component_id/instruction/download"} component={InstructionDownload} />
              <Route path={"/codemirror"} component={CodeMirrorExample} />
              <Route path={`/public-form/:id/component/:component_id/o_id/:o_id`} component={PublicForm} />
              <Route path={`/project-v2/:id/component/:component_id`} component={PublicForm} />

              <Route component={NotFound} />
            </Switch>
          </ThemeProvider>)
        }}
      </CacheBuster>
    )
  }
}


export default connector(compose(
  withStyles(styles)
)(Expent));